import { StrykePositionManagerV2ABI } from '@apps-orangefi/wagmi/abis'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { encodeFunctionData, encodeAbiParameters, AbiParameter } from 'viem'
import { BaseError } from 'wagmi'

import {
  useSimulateContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling,
  useWriteContractWithErrorHandling,
} from '../common'
import { ReserveLiquidityParam } from '../lpdfi'

const burnPositionStruct = [
  { name: 'pool', type: 'address' },
  { name: 'hook', type: 'address' },
  { name: 'tickLower', type: 'int24' },
  { name: 'tickUpper', type: 'int24' },
  { name: 'shares', type: 'uint128' },
] as const satisfies AbiParameter[]

export const useBatchReserveLiquidity = (
  positionManagerAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  reserveLiquidityParams: ReserveLiquidityParam[],
  isEnabled: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const enabled = useMemo(
    () =>
      !!positionManagerAddress && !!handlerAddress && !isEmpty(reserveLiquidityParams) && isEnabled,
    [positionManagerAddress, handlerAddress, reserveLiquidityParams, isEnabled]
  )

  const args = useMemo(() => {
    const batchReserveCallData = handlerAddress
      ? reserveLiquidityParams.map(param => {
          const reserveLiquidityCallData = encodeAbiParameters(burnPositionStruct, [
            param.pool,
            param.hook,
            param.tickLower,
            param.tickUpper,
            param.shares,
          ])

          return encodeFunctionData({
            abi: StrykePositionManagerV2ABI,
            functionName: 'reserveLiquidity',
            args: [handlerAddress, reserveLiquidityCallData],
          })
        })
      : []

    return [batchReserveCallData] as const
  }, [handlerAddress, reserveLiquidityParams])

  const {
    data,
    failureReason,
    isError: simuIsError,
  } = useSimulateContractWithErrorHandling({
    address: positionManagerAddress!,
    abi: StrykePositionManagerV2ABI,
    functionName: 'multicall',
    args,
    query: {
      enabled,
    },
  })

  const {
    data: hash,
    writeContract,
    submittedAt,
  } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        console.debug('batch reserve liquidity error occured:', error, `args: ${args}`)
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (!!failureReason) {
      console.log('batch reserve liquidity failure reason: ', failureReason)
    }
  }, [failureReason])

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  const onReserve = useCallback(() => {
    if (!isWriteReady) {
      return
    }
    writeContract(data!.request)
  }, [writeContract, isWriteReady, data?.request])

  return {
    isWriteReady,
    hash,
    waitLoading,
    isSuccess,
    onReserve,
    isSubmitted: submittedAt > 0,
  }
}

export const useBatchWithdrawReserveLiquidity = (
  positionManagerAddress: AddressType | undefined,
  handlerAddress: AddressType | undefined,
  withdrawReserveLiquidityParams: ReserveLiquidityParam[],
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const enabled = useMemo(
    () => !!positionManagerAddress && !!handlerAddress && !isEmpty(withdrawReserveLiquidityParams),
    [positionManagerAddress, handlerAddress, withdrawReserveLiquidityParams]
  )

  const args = useMemo(() => {
    const batchReserveCallData = handlerAddress
      ? withdrawReserveLiquidityParams.map(param => {
          const reserveLiquidityCallData = encodeAbiParameters(burnPositionStruct, [
            param.pool,
            param.hook,
            param.tickLower,
            param.tickUpper,
            param.shares,
          ])

          return encodeFunctionData({
            abi: StrykePositionManagerV2ABI,
            functionName: 'withdrawReserveLiquidity',
            args: [handlerAddress, reserveLiquidityCallData],
          })
        })
      : []

    return [batchReserveCallData] as const
  }, [handlerAddress, withdrawReserveLiquidityParams])

  const {
    data,
    failureReason,
    isError: simuIsError,
  } = useSimulateContractWithErrorHandling({
    address: positionManagerAddress!,
    abi: StrykePositionManagerV2ABI,
    functionName: 'multicall',
    args,
    query: {
      enabled,
    },
  })

  const {
    data: hash,
    writeContract,
    submittedAt,
  } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        console.debug('batch reserve liquidity error occured:', error, `args: ${args}`)
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({ hash })

  useEffect(() => {
    if (!!failureReason) {
      console.log('batch withdraw reserved liquidity failure reason: ', failureReason)
    }
  }, [failureReason])

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWriteReady,
    hash,
    isSuccess,
    onWithdraw: () => {
      if (!isWriteReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
