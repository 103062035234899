import { graphql } from '../types/dopex/gql'

export const getDopexStrikeListQuery = graphql(/* GraphQL */ `
  query getDopexStrikeList($tokenIds: [ID!]!) {
    strikes(where: { id_in: $tokenIds }, orderBy: tickLower, orderDirection: desc, first: 1000) {
      id
      handler
      pool
      hook
      tickLower
      tickUpper
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
    }
  }
`)

export const getDopexLpPositionsListQuery = graphql(`
  query getDopexLpPositionsList($userIds: [String!]!) {
    lppositions(where: { user_in: $userIds, shares_gt: "1" }, first: 1000) {
      id
      pool
      hook
      shares
      initialLiquidity
      user
      handler
      strike {
        id
        pool
        tickLower
        tickUpper
        totalLiquidity
        usedLiquidity
        totalShares
      }
    }
  }
`)

export const getStrykeReservedLiquiditiesQuery = graphql(`
  query getStrykeReservedLiquidities($user: String!, $handler: String!) {
    lppositions(where: { user: $user, handler: $handler, reservedLiquidity_gt: 0 }, first: 1000) {
      id
      pool
      hook
      shares
      reservedLiquidity
      lastReserve
      user
      handler
      strike {
        id
        pool
        tickLower
        tickUpper
      }
    }
  }
`)

export const getDopexDailyStrikeEarningsListQuery = graphql(`
  query getDopexDailyStrikeEarningsList(
    $tokenIds: [ID!]!
    $tokenIdsCount: Int!
    $startTime: BigInt!
  ) {
    dailyDonations(
      where: { strike_: { id_in: $tokenIds }, donation_gt: "0", start_gte: $startTime }
      orderBy: start
      orderDirection: desc
      first: $tokenIdsCount
    ) {
      id
      donation
      sqrtPriceX96
      start
      strike {
        id
      }
    }
    dailyFeeCompounds(
      where: { strike_: { id_in: $tokenIds }, compound_gt: "0", start_gte: $startTime }
      orderBy: start
      orderDirection: desc
      first: $tokenIdsCount
    ) {
      id
      compound
      sqrtPriceX96
      start
      strike {
        id
      }
    }
  }
`)

export const getStrykeLiquidityListQuery = graphql(`
  query getStrykeLiquidityList($tokenIds: [ID!]!, $skip: Int) {
    strikes(
      where: { id_in: $tokenIds }
      orderBy: tickLower
      orderDirection: desc
      first: 1000
      skip: $skip
    ) {
      id
      handler
      pool
      hook
      reservedLiquidity
      usedLiquidity
      totalLiquidity
      tickLower
      tickUpper
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
    }
  }
`)
