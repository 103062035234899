import { BN } from '@apps-orangefi/lib'
import {
  type TotalWithdrawableReserveLiquidity,
  type WithdrawableReserveLiquidity,
} from '@apps-orangefi/lib/types'
import { Button, LineLoader } from '@apps-orangefi/ui/atoms'
import { IconInfo } from '@apps-orangefi/ui/molecules/icons'
import { ReserveLiquidityParam } from '@apps-orangefi/wagmi/hooks'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  reservedLPStatus: {
    withdrawReservedLiquidityParams: ReserveLiquidityParam[]
    withdrawableReserveLiquidities: WithdrawableReserveLiquidity[]
    totalWithdrawableReserveLiquidity: TotalWithdrawableReserveLiquidity
    fetching: boolean
  }
  onWithdrawReservedLP: () => void
  isConnected: boolean
  isActiveChainSupported: boolean
}

export const V2RedeemReservedLiquidity = ({
  reservedLPStatus,
  onWithdrawReservedLP,
  isConnected,
  isActiveChainSupported,
}: Props) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  const buttonLabel = t('WIDGET.ACTION.REDEEM_RESERVED_LIQUIDITY')

  const { token0, token1 } = reservedLPStatus.totalWithdrawableReserveLiquidity

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setEnabled(isConnected && isActiveChainSupported)
  }, [isConnected, isActiveChainSupported])

  useEffect(() => {
    setIsAvailable(enabled && !isEmpty(reservedLPStatus.withdrawReservedLiquidityParams))
  }, [enabled, reservedLPStatus])

  return (
    <div
      className={twMerge('flex flex-col gap-3 w-full h-fit font-inter text-secondary px-4 pb-5')}
    >
      <div className="flex flex-row justify-between items-center bg-cards">
        <div className="flex flex-row w-full items-center">
          <div className="type-sm-medium text-secondary">
            {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.TITLE')}
          </div>
          {/* hide beacuse it does not have content */}
          {/* <IconInfo size={16} className="ml-2" /> */}
        </div>
      </div>
      <div className="w-full flex flex-col bg-cards p-2 rounded-lg border border-border">
        {reservedLPStatus.fetching ? (
          <div className="flex flex-row justify-center">
            <LineLoader />
          </div>
        ) : (
          <>
            {isEmpty(reservedLPStatus.withdrawableReserveLiquidities) ? (
              <div className="text-center">{t('MODEL.REDEEM_RESERVED_LIQUIIDTY.NO_DATA')}</div>
            ) : (
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <div className="flex flex-col w-full">
                    <div className="type-sm-semibold text-secondary">
                      {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.LP_POSITIONS.HEADING')}
                    </div>
                    <div className="flex flex-row justify-between type-xs-medium mt-3 mb-2">
                      <div className="w-1/3">
                        {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.LP_POSITIONS.PRICE')}
                      </div>
                      <div className="w-1/3">
                        {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.LP_POSITIONS.SIZE')}
                      </div>
                      <div className="w-1/3">
                        {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.LP_POSITIONS.WITHDRAWABLE')}
                      </div>
                    </div>
                    <div className="[&>*:first-child]:mt-0 max-h-32 overflow-y-scroll">
                      {reservedLPStatus.withdrawableReserveLiquidities.map((lp, i) => (
                        <div
                          className="flex flex-row justify-between items-baseline w-full mt-2"
                          key={i}
                        >
                          <div className="type-sm-medium text-primary w-1/3">
                            $<span className="">{lp.strikePrice.toFixed(2)}</span>
                          </div>
                          <div className="w-2/3">
                            {lp.token0 && (
                              <div className="flex flex-row justify-start">
                                <div className="type-sm-medium text-primary w-1/2">
                                  <span className="">{lp.token0.size.toFixed(6, BN.ROUND_UP)}</span>{' '}
                                  {lp.token0.symbol}
                                </div>
                                <div
                                  className={twMerge(
                                    'type-sm-medium text-primary w-1/2',
                                    lp.token0.withdrawable.gt(0)
                                      ? 'text-primary'
                                      : 'text-darkTheme-400'
                                  )}
                                >
                                  <span className="">
                                    {lp.token0.withdrawable.toFixed(6, BN.ROUND_UP)}
                                  </span>{' '}
                                  {lp.token0.symbol}
                                </div>
                              </div>
                            )}
                            {lp.token1 && (
                              <div className="flex flex-row justify-start">
                                <div className="type-sm-medium text-primary w-1/2">
                                  <span className="">{lp.token1.size.toFixed(6, BN.ROUND_UP)}</span>{' '}
                                  {lp.token1.symbol}
                                </div>
                                <div
                                  className={twMerge(
                                    'type-sm-medium text-primary w-1/2',
                                    lp.token1.withdrawable.gt(0)
                                      ? 'text-primary'
                                      : 'text-darkTheme-400'
                                  )}
                                >
                                  <span className="">
                                    {lp.token1.withdrawable.toFixed(6, BN.ROUND_UP)}
                                  </span>{' '}
                                  {lp.token1.symbol}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="border-t border-border h-px my-4" />
                <div className="flex flex-col justify-between items-start">
                  <div className="flex flex-row items-center">
                    <div className="type-sm-medium text-secondary">
                      {t('MODEL.REDEEM_RESERVED_LIQUIIDTY.REDEEMABLE_AMOUNTS')}
                    </div>
                    {/* hide beacuse it does not have content */}
                    {/* <IconInfo size={16} className="ml-2" /> */}
                  </div>
                  <div className="flex flex-col text-right  self-end">
                    {token0 && (
                      <div>
                        <span
                          className={
                            token0.withdrawable.gt(0) ? 'text-primary' : 'text-darkTheme-400'
                          }
                        >
                          {token0.withdrawable.toFixed(6, BN.ROUND_UP)}
                        </span>{' '}
                        / {token0.size.toFixed(6, BN.ROUND_UP)} {token0.symbol}
                      </div>
                    )}
                    {token1 && (
                      <div>
                        <span
                          className={
                            token1.withdrawable.gt(0) ? 'text-primary' : 'text-darkTheme-400'
                          }
                        >
                          {token1.withdrawable.toFixed(6, BN.ROUND_UP)}
                        </span>{' '}
                        / {token1.size.toFixed(6, BN.ROUND_UP)} {token1.symbol}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {mounted && isConnected && isActiveChainSupported && (
        <Button
          label={buttonLabel}
          disabled={!isAvailable}
          className="self-center w-full px-6 py-3 md:py-4 rounded-xl md:rounded-2xl"
          onSubmit={onWithdrawReservedLP}
        />
      )}
    </div>
  )
}
