import camelotDnImageUrl from '@apps-orangefi/assets/images/products/camelot/delta-neutral.svg'
import camelotImageUrl from '@apps-orangefi/assets/images/products/camelot/dex.svg'
import strykeImageUrl from '@apps-orangefi/assets/images/products/stryke/concept.svg'
import boopTokenImageUrl from '@apps-orangefi/assets/images/tokens/boop.svg'
import ethTokenImageUrl from '@apps-orangefi/assets/images/tokens/eth-outline.svg'
import usdcTokenImageUrl from '@apps-orangefi/assets/images/tokens/usdc-outline.svg'
import usdceTokenImageUrl from '@apps-orangefi/assets/images/tokens/usdce-outline.svg'
import weEthTokenImageUrl from '@apps-orangefi/assets/images/tokens/weETH.svg'
import wstEthTokenImageUrl from '@apps-orangefi/assets/images/tokens/wstETH.svg'
import LottieUsdcArb10 from '@apps-orangefi/assets/lottie/How-it-works-USDC-ARB.json'
import LottieUsdcWbtc10 from '@apps-orangefi/assets/lottie/How-it-works-USDC-WBTC.json'
import LottieUsdcWeth from '@apps-orangefi/assets/lottie/How-it-works-USDC-WETH.json'
import LottieUsdcWeth10 from '@apps-orangefi/assets/lottie/usdc-weth-lottie.json'
import {
  ProductInfo,
  category,
  strategy,
  vaultVersion,
  amm,
  derivative,
  productType,
  ProductKey,
  productKey,
  merklRewardStatus,
  ArbitrumProductKey,
} from '@apps-orangefi/lib/types'

export const productInfoList: { [key in ArbitrumProductKey]: ProductInfo } = {
  [productKey.CamelotWethUsdtVault]: {
    productName: 'WETH-USDT',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's ETH-USDT v3 AMM pool, utilizing a delta-neutral strategy within a narrow price range to minimize losses stemming from market volatility.",
    overview:
      "Deposited WETH is managed in the Delta Neutral Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 WETH-USDT Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: usdceTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    aprBaseDays: 0,
  },

  [productKey.CamelotUsdcArbVault]: {
    productName: 'USDC-ARB',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's ARB-USDC v3 AMM pool, utilizing a delta-neutral strategy within a narrow price range to minimize losses stemming from market volatility.",
    overview:
      "Deposited USDC is managed in the Delta Neutral Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 USDC-ARB Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: usdceTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    aprBaseDays: 0,
  },
  [productKey.CamelotWethArbVault]: {
    productName: 'WETH-ARB',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's ARB-ETH v3 AMM pool, utilizing a delta-neutral strategy within a narrow price range to minimize losses stemming from market volatility.",
    overview:
      "Deposited WETH is managed in the Delta Neutral Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/0xe51635ae8136aBAc44906A8f230C2D235E9c195F' target='__blank' class='type-base-semibold text-white'>CamelotV3 ETH-ARB Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
  },
  [productKey.CamelotUsdcDaiVault]: {
    productName: 'USDC-DAI',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's USDC-DAI v3 AMM pool with a narrow price range.",
    overview:
      "Deposited USDC is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 USDC-DAI Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to USDC.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip, productType.Pegged],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.CamelotWethWbtcVault]: {
    productName: 'WETH-WBTC',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's WETH-WBTC v3 AMM pool, utilizing a delta-neutral strategy within a narrow price range to minimize losses stemming from market volatility.",
    overview:
      "Deposited WETH is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 ETH-WBTC Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    productType: [productType.BlueChip],
    platform: {
      amm: amm.CAMELOT,
    },
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
  },
  [productKey.CamelotUsdcUsdceVault]: {
    productName: 'USDC.e-USDC',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's USDC-USDC.e v3 AMM pool with a narrow price range.",
    overview:
      "Deposited USDC.e is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 USDC.e-USDC Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to USDC.e.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    productType: [productType.BlueChip, productType.Pegged],
    platform: {
      amm: amm.CAMELOT,
    },
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.CamelotUsdceUsdtVault]: {
    productName: 'USDC.e-USDT',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's USDT-USDC.e v3 AMM pool with a narrow price range.",
    overview:
      "Deposited USDC.e is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 USDC.e-USDT Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to USDC.e.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip, productType.Pegged],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.CamelotWethUsdcVault]: {
    productName: 'WETH-USDC',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's WETH-USDC v3 AMM pool with a narrow price range.",
    overview:
      "Deposited WETH is managed in the Delta Neutral Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 ETH-USDC Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
  },
  [productKey.CamelotUsdcWethVault]: {
    productName: 'USDC-WETH',
    version: vaultVersion.DN,
    caption: 'Camelot Delta Neutral Vault',
    description:
      "This vault provides a consistent yield through Camelot's USDC-ETH v3 AMM pool with a narrow price range.",
    overview:
      "Deposited USDC is managed in the Delta Neutral Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 ETH-USDC Pool</a> and to construct hedge positions on <a href='https://app.aave.com/' target='__blank' class='type-base-semibold text-white'>Aave</a>. The vault adjusts its liquidity range dynamically in response to market volatility, expanding during periods of high volatility and contracting when volatility decreases. It also implements a delta-neutral strategy, leveraging deposited assets as collateral to borrow the corresponding asset, thus providing liquidity and reducing risks associated with price fluctuations.",
    tags: ['Camelot', 'Delta Neutral'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.DeltaNeutral,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
  },
  [productKey.CamelotWstethWethVault]: {
    productName: 'wstETH-WETH',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's wstETH-WETH v3 AMM pool with a narrow price range.",
    overview:
      "Deposited wstETH is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 wstETH-WETH Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to wstETH.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: wstEthTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip, productType.Pegged],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.CamelotWeethWethVault]: {
    productName: 'weETH-WETH',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's weETH-WETH v3 AMM pool with a narrow price range.",
    overview:
      "Deposited weETH is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 weETH-WETH Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to weETH.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: weEthTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip, productType.Pegged],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.CamelotEzethWethVault]: {
    productName: 'ezETH-WETH',
    version: vaultVersion.DN,
    caption: 'Camelot Stable Vault',
    description:
      "This vault provides a consistent yield through Camelot's ezETH-WETH v3 AMM pool with a narrow price range.",
    overview:
      "Deposited ezETH is managed in the Stable Vault, where it's used to supply liquidity in the <a href='https://app.camelot.exchange/pools/' target='__blank' class='type-base-semibold text-white'>CamelotV3 ezETH-WETH Pool</a>. The vault dynamically adjusts its liquidity range in response to market volatility, typically maintaining a range within a few ticks when these assets remain pegged. In the event of a depeg incident, a stop-loss function is activated to automatically exit the liquidity pool position and convert all assets to ezETH.",
    tags: ['Camelot', 'Stable'],
    imageUrls: {
      concept: camelotDnImageUrl,
      token: wstEthTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.PeggedPair,
    platform: {
      amm: amm.CAMELOT,
    },
    productType: [productType.BlueChip, productType.Pegged],
    theme: {
      main: 'camelot',
      tag: 'tag-camelot',
    },
    showAPR: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.DeprecatedStrykeWethUsdcVault]: {
    productName: 'WETH-USDC (deprecated)',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing WETH into the Orange Stryke WETH/USDC vault provides liquidity to the Stryke WETH/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Closed,
    strategy: strategy.Swap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcWeth10,
    needMigration: true,
  },
  [productKey.NoswapStrykeWethUsdcVault]: {
    productName: 'Noswap WETH-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'This vault is an active liquidity manager vault that adopts Stryke, an option protocol utilizes liquidity position of concentrated liquidity type AMM. This provides higher revenue to liquidity providers compare to providing liquidity directly to AMM.',
    tags: ['Stryke', 'No swap strategy'],
    imageUrls: {
      concept: strykeImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.NoSwap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcWeth10,
  },
  [productKey.StrykeWethUsdcVault]: {
    productName: 'WETH-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'This vault is an active liquidity manager vault that adopts Stryke, an option protocol utilizes liquidity position of concentrated liquidity type AMM. This provides higher revenue to liquidity providers compare to providing liquidity directly to AMM.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcWeth10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeWbtcUsdcVault]: {
    productName: 'WBTC-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke WBTC/USDC vault provides liquidity to the Stryke WBTC/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcWbtc10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeArbUsdcVault]: {
    productName: 'ARB-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke ARB/USDC vault provides liquidity to the Stryke ARB/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeBoopWethVault]: {
    productName: 'BOOP-WETH',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing BOOP into the Orange Stryke BOOP/WETH vault provides liquidity to the Stryke BOOP/WETH pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: boopTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.NoSwap,
    platform: {
      amm: amm.UNISWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    spaceshipBlackListed: true,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykePancakeWethUsdcVault]: {
    productName: 'WETH-USDC',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'This vault is an active liquidity manager vault that adopts Stryke, an option protocol utilizes liquidity position of concentrated liquidity type AMM. This provides higher revenue to liquidity providers compare to providing liquidity directly to AMM.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.PANCAKESWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykePancakeWbtcUsdcVault]: {
    productName: 'WBTC-USDC',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke WBTC/USDC vault provides liquidity to the Stryke WBTC/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.PANCAKESWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykePancakeArbUsdcVault]: {
    productName: 'ARB-USDC',
    version: vaultVersion.V1,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke ARB/USDC vault provides liquidity to the Stryke ARB/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.PANCAKESWAP,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeSushiWethUsdcVault]: {
    productName: 'WETH-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'This vault is an active liquidity manager vault that adopts Stryke, an option protocol utilizes liquidity position of concentrated liquidity type AMM. This provides higher revenue to liquidity providers compare to providing liquidity directly to AMM.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: ethTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.SUSHI,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeSushiWbtcUsdcVault]: {
    productName: 'WBTC-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke WBTC/USDC vault provides liquidity to the Stryke WBTC/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.SUSHI,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
  [productKey.StrykeSushiArbUsdcVault]: {
    productName: 'ARB-USDC',
    version: vaultVersion.LPDfi,
    caption: 'Stryke CLAMM Liquidity Manager',
    description: '',
    overview:
      'Depositing USDC into the Orange Stryke ARB/USDC vault provides liquidity to the Stryke ARB/USDC pool. This liquidity is automatically managed as per the pre-defined features outlined below. Shareholders of this vault receive swap fees, option premiums, and rewards distributed via Merkl. However, rewards on Merkl are not automatically compounded and must be claimed manually by the users.',
    tags: ['Stryke', 'CLAMM Liquidity Manager'],
    imageUrls: {
      concept: strykeImageUrl,
      token: usdcTokenImageUrl,
    },
    category: category.Lab,
    strategy: strategy.Swap,
    platform: {
      amm: amm.SUSHI,
      derivative: derivative.STRYKE,
    },
    productType: [productType.BlueChip, productType.DualLiquidity],
    theme: {
      main: 'stryke',
      tag: 'tag-stryke',
    },
    showAPR: true,
    lottieAnimation: LottieUsdcArb10,
    merklRewardStatus: merklRewardStatus.Inactive,
  },
}
